import { Injectable } from '@angular/core';
import {firebase} from '@firebase/app';
import '@firebase/messaging';
import {environment} from '../../environments/environment';
import {UserService} from "./user.service";
import {RestService} from "../api/rest.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private rest: RestService,
              private userService: UserService,
              ) { }

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      navigator.serviceWorker.ready.then((registration) => {
        // Don't crash an error if messaging not supported
        if (!firebase.messaging.isSupported()) {
          resolve();
          return;
        }

        const messaging = firebase.messaging();

        // Register the Service Worker
        messaging.useServiceWorker(registration);

        // Initialize your VAPI key
        messaging.usePublicVapidKey(
            environment.firebase.vapidKey
        );

        // Optional and not covered in the article
        // Listen to messages when your app is in the foreground
        messaging.onMessage((payload) => {
          // console.log(payload);
        });
        // Optional and not covered in the article
        // Handle token refresh
        messaging.onTokenRefresh(() => {
          messaging.getToken().then(
              (refreshedToken: string) => {
                // console.log(refreshedToken);
              }).catch((err) => {
          });
        });

        resolve();
      }, (err) => {
        reject(err);
      });
    });
  }

  requestPermission(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!Notification) {
        resolve();
        return;
      }
      if (!firebase.messaging.isSupported()) {
        resolve();
        return;
      }
      try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();

        const token: string = await messaging.getToken();

        // 여기서 토큰을 DB에 저장해야 할꺼다.
        console.log(['userService', this.userService.getUser()]);

        console.log('User notifications token:', token);
        let param = {
          token: token,
          user_info: this.userService.getUser()
        };

        this.rest.setUserToken(param).then( res => {
          console.log(['setUserToken', res]);

        });

      } catch (err) {
        // No notifications granted
      }

      resolve();
    });
  }
}
