import { Injectable } from '@angular/core';
import {CanActivate} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {CommonService} from './common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthenticationService,
              private common: CommonService) { }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  // }
  // canActivate(): boolean {
  //   return this.authService.isAuthenticated();
  // }
  canActivate(): boolean {
    console.log(['canActivate', this.common.isLoggedIn]);
    return this.common.isLoggedIn;
  }

  // canDeactivate(): boolean {
  //   console.log(['canDeactivate', this.common.isLoggedIn]);
  //   return this.common.isLoggedIn;
  // }

}
