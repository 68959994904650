import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {CommonService} from '../../services/common.service';
import {RestService} from '../../api/rest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {AlertController} from '@ionic/angular';
import {ApiService} from "../../services/api.service";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-find',
  templateUrl: './find.component.html',
  styleUrls: ['./find.component.scss'],
})
export class FindComponent implements OnInit {
  events:any;
  constructor(private authService: AuthenticationService,
              private alertCtrl: AlertController,
              private apiService: ApiService,
              private common: CommonService,
              private rest: RestService,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private storage: Storage,
              ) {
    this.route.queryParams.subscribe((params) => {
      console.log(['queryParam', params, params.a]);
      console.log(['qrKey', this.common.qrKey, this.common.isLoggedIn]);
      console.log(['user', userService.getUser()]);

      // const args = Object.assign(params, userService.getUser());


      // 광고 정보를 가져온다.
      this.rest.getEventInfo({event_hash: params.a}).then( async (result) => {
        console.log(['getDetail result', result]);
        if (result && result[0]) {
          const event = result[0].eventInfo[0];
          this.events = event;
          console.log(['event', event, event.end_date]);

          const a = Date.now();
          const b = new Date(event.end_date + ' 23:59:59').getTime();
          const c = new Date(event.start_date + ' 00:00:00').getTime();
          // const b = new Date('2019.11.15 23:59:59').getTime();
          // const d = new Date('2019.11.15').getTime();
          // const c = new Date('2019-11-15 23:00:39').getTime();
          console.log(['date', a, b, c]);

          if (a > b) {
            console.log(['종료된 이벤트']);
            const alert = await this.alertCtrl.create({
              header: '종료안내',
              cssClass: 'hint-alert',
              // subHeader: 'Subtitle',
              message: '이벤트 기간이 종료되었습니다.<br>다음 이벤트에 참여해주세요.',
              buttons: [
                {
                  text: '확인',
                  handler: () => {
                    this.router.navigate(['/']);
                  },
                },
              ],
            });
            alert.present();

            return;
          }

          if (c > a) {
            const alert = await this.alertCtrl.create({
              header: '안내',
              cssClass: 'hint-alert',
              // subHeader: 'Subtitle',
              message: '이벤트 기간이 아닙니다.<br>이벤트 기간을 확인해주세요.',
              buttons: [
                {
                  text: '확인',
                  handler: () => {
                    this.router.navigate(['/']);
                  },
                },
              ],
            });
            alert.present();
            return;
          }
          // 날짜 체크해서 지난 이벤트면 중단한다.

          // 로그인 사용하는 경우
          if (this.apiService.useLogin) {
            this.checkLogin().then( (res) => {
              console.log(['const storage', res]);
              if (res) {
                console.log(['res join_type', res[0].join_type, this.rest.loginUser]);
                // 찾기완료
                const data = {
                  // user_code : this.rest.loginUser[0].user_code,
                  user_code : res[0].user_code,
                  event_code : this.events.event_code,
                  event_hash : params.a,
                  block_hash : params.b,
                  qr_code : params.c,
                  team_check : params.d,
                  block_type : params.e,
                  score: 0,
                };
                this.findBlock(data);
              } else {
                console.log(['not login set storage']);
                this.storage.set(this.common.qrKey, params);
                this.router.navigateByUrl('/login');
              }
            });
          } else {
            // 로그인 없이 하는 경우
            this.checkDevice().then( (res) => {
              console.log(['checkDevice', res, this.events]);
              // 찾기완료
              const data = {
                // user_code : this.rest.loginUser[0].user_code,
                user_code : res.user_code,
                event_code : this.events.event_code,
                device_id: res.device_id,
                event_hash : params.a,
                block_hash : params.b,
                qr_code : params.c,
                team_check : params.d,
                block_type : params.e,
                score: 0,
              };
              this.findBlock(data);
            });
          }
        }
      });
    });
  }

  ngOnInit() {}

  // 사용자 로그인 체크
  async checkLogin() {
    return await this.storage.get(this.common.authKey);
  }

  // 디바이스 아이디 체크
  async checkDevice() {
    return await this.storage.get(this.common.deviceKey);
  }

  // 광고 정보 확인
  async getEvent(data) {
    console.log(['getEvent', data]);
    // 날짜 체크
    // 광고 정보를 가져온다.
    return await this.rest.getDetail(data);
  }

  // 조각 찾기
  async findBlock(data) {
    this.common.findBlock(data).then((block) => {
      console.log(['findBlock res', block]);
    }, (fail) => {
      console.log(['findBlock fail', fail]);
    });
  }

}
