import { Injectable } from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {RestService} from '../api/rest.service';
import {IntervalInfoPage} from '../interval-info/interval-info.page';
// import {FinalModalPage} from '../user/final-modal/final-modal.page';
import {PaymentService} from './payment.service';
import {PaymentInfo} from '../data/paymentInfo';
import {UserService} from './user.service';
import {User} from '../data/user';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public isLoggedIn = false;
  public isUser = false;
  public authKey = 'auth-user';
  public deviceKey = 'device-info';
  public lngKey = 'select-lang';
  public qrKey = 'find-qr';
  public inviteKey = 'invite-user';
  public introKey = 'intro-time';
  paymentInfo: PaymentInfo;

  // public themeColor: string = '#0C0F1F';
  public themeColor: object = {
    bgColor: '#0C0F1F',
    textColor: '#FFFFFF',
  };
  coords: any = {
    latitude: 0,
    longitude: 0,
  };
  constructor(private rest: RestService,
              private router: Router,
              private modalCtrl: ModalController,
              private navCtrl: NavController,
              private alertCtrl: AlertController,
              private paymentService: PaymentService,
              private userService: UserService,
  ) {
    this.deviceKey = 'device-info';
    // if (navigator.geolocation) {
    //   console.log([' = navigator.geolocation = ']);
    //   navigator.geolocation.watchPosition((position) => {
    //     console.log([' === watchPosition === ', position]);
    //     this.coords = position.coords;
    //   }, (fail) => {
    //     console.log(['fail', fail]);
    //   }, {enableHighAccuracy: true, timeout:100});
    // }
  }

  async blockAdModal(param) {
    const modal = await this.modalCtrl.create({
      component: IntervalInfoPage,
      componentProps: param,
    });
    modal.onDidDismiss().then((result) => {
      console.log(['onDidDismiss', result]);
    });
    return await modal.present();
  }

  // async finalModal(param) {
  //   const modal = await this.modalCtrl.create({
  //     component: FinalModalPage,
  //     componentProps: param,
  //   });
  //   // modal.onDidDismiss().then((result) => {
  //   //   console.log(['onDidDismiss', result]);
  //   // });
  //   return await modal.present();
  // }

  async pleaseLogin() {
    const alert = await this.alertCtrl.create({
      header: '로그인 필요',
      cssClass: 'hint-alert',
      // subHeader: 'Subtitle',
      message: '로그인 하시고 다양한 혜택을 확인하세요.',
      buttons: [
        {
          text: '확인',
          handler: () => {
            console.log('Confirm Okay');
            // this.showPg();
            this.router.navigate(['login']);
          },
        },
      ],
    });
    alert.present();
  }

  findBlock(data) {
    console.log(['common findBlock', data, this.coords]);
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((pos) => {
    //     this.coords = pos.coords;
    //     // pos.coords.latitude;
    //     // pos.coords.longitude;
    //     console.log(['find pos', pos]);
    //   },(fail) => {
    //     console.log(['find fail', fail]);
    //   },{enableHighAccuracy: true, timeout:0});
    // }

    if (data) {
      if (this.coords) {
        data.latitude = this.coords.latitude;
        data.longitude = this.coords.longitude;
      }

      if (data.team_check === 'true') {
        if (this.rest.loginUser.teams.length > 0 ) {
          // 팀 선택 팝업 띄워야 한다.
        } else {
          // 팀 생성 후 참여 유도 팝업
        }
      }
    }

    console.log(['params', data]);

    return this.rest.setRecord(data).then((result) => {
      console.log(['scan', result]);
      if (result && result[0]) {
        if (result[0].success) {
          this.rest.showToast('블럭을 찾았습니다.', 2000);
          // this.qrResultString = '';

          const final = result[0].is_final;
          let isCommon = true;

          // 광고 팝업을 띄운다.
          if (result[0].ad_img) {
            const adModal = this.blockAdModal({
              title : result[0].ad_title,
              image : result[0].ad_img,
              targetUrl : result[0].click_url,
              is_final : final,
            });

            adModal.then(() => {
              // if (final) {
              //   // 완료 팝업 띄우기
              //   this.finalModal({});
              // }
            });

          }

          if (final) {
            // 완료 팝업 띄우기
            isCommon = false;
          }

          const navigationExtras: NavigationExtras = {
            state: {
              data: {
                id: data.event_hash,
                is_final: result[0].is_final,
                is_common : isCommon,
                // ads: result[0].ads
                // , blocks : result[0].blocks
                // , rewards : result[0].rewards
                // , entry :  result[0].entry[0]
                // , rankers : result[0].rankers
              },
            },
          }
          // this.router.navigate(['detail'], navigationExtras);
          this.navCtrl.navigateForward('/event/' + data.event_code);
          return true;
        } else {
          // this.scanner.restartScan();
          if (result[0].exist_block) {
            this.rest.showToast('이미 찾은 블럭입니다.\n다른 블럭을 찾아주세요.', 3000);
          } else {
            this.rest.showToast('블럭 찾기에 실패했습니다.\n다시 시도해주세요.', 3000);
          }
          // this.router.navigate(['home']);
          this.navCtrl.navigateRoot(['/']);
          return false;
        }
      } else {
        // this.scanner.restartScan();
        this.rest.showToast('블럭 찾기에 실패했습니다.\n다시 시도해주세요.', 3000);
        return false;
      }
    });
  }
}
