import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {RestService} from '../../api/rest.service';
import {User} from '../../data/user';
import {UserService} from '../../services/user.service';
import {Reward} from '../../data/reward';
import {RewardService} from '../../services/reward.service';
import {InfoModalPage} from '../info-modal/info-modal.page';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'app-final-modal',
  templateUrl: './final-modal.page.html',
  styleUrls: ['./final-modal.page.scss'],
})
export class FinalModalPage implements OnInit, OnDestroy {
  loginUser: any;
  // adHash: any;
  events: any;
  myRank: number;
  lastAt: any;
  eventGoods: any;
  rewards: any;
  imageUrl: any;
  user: User;

  constructor(private alertCtrl: AlertController,
              private common: CommonService,
              public modal: ModalController,
              private modalCtrl: ModalController,
              private infoModalCtrl: ModalController,
              private rest: RestService,
              private navParams: NavParams,
              private userService: UserService,
              private rewardService: RewardService,
              ) {
    this.imageUrl = this.rest.imageUrl;
    this.user = this.userService.getUser();
    console.log(['this.navParam', this.navParams, this.user]);
  }

  ngOnInit() {

    if (this.rest.loginUser) {
      console.log(['success info ', this.rest.loginUser]);
      this.loginUser = this.rest.loginUser[0];

      // this.rest.getMyToken({user_code: this.rest.loginUser[0].user_code}).then( (result) => {
      //   this.myTokens.T = result[0].T;
      // });

    }

    // this.adHash = this.navParams.get('event_hash');
    this.events = this.navParams.get('events');
    this.myRank = this.navParams.get('my_rank');
    this.lastAt = this.navParams.get('last_at');
    this.rewards = this.navParams.get('rewards');
    this.eventGoods = this.navParams.get('event_goods');

    console.log(['rank', this.events, this.myRank, this.eventGoods, this.rewards]);
  }

  closeModal() {
    this.modal.dismiss();
  }

  dismiss() {
    this.modal.dismiss();
  }

  async info() {
    console.log(['info']);
    // 로그인 여부 확인
    if (this.user) {
      const param = {
        event_hash: this.events.event_hash,
        user_code: this.user.user_code,
      };
      this.rest.getWinningCheck(param).then( async (res) => {
        console.log(['getWinningCheck', res]);

        if (res[0].success) {
          this.dismiss();
          if (res[0].is_info) {
            // userInfo2.reward = res[0].reward;
            // userInfo2.my_rank = res[0].my_rank;
            // userInfo2.reward_seq = res[0].reward_seq;


          } else {
            // 정보입력 정보가 없다면
            // 당첨정보 셋팅해서 넘기기
            const reward = new Reward();
            reward.userInfo = this.user;
            reward.event_hash = this.events.event_hash;
            reward.reward = res[0].reward;
            reward.reward_seq = res[0].reward_seq;
            reward.my_rank = res[0].my_rank;
            reward.reward_type = this.events.reward_type;
            if (this.events.reward_type === 'REWARD_TYPE03') {
              reward.reward_list = this.rewards;
            }

            console.log(['reward', reward]);
            this.rewardService.setReward(reward);

            const modal = await this.infoModalCtrl.create({
              component: InfoModalPage,
              componentProps: param
            });
            modal.onDidDismiss().then((result) => {
              console.log(['onDidDismiss', result]);
            });
            await modal.present();
          }
        } else {
          // 아쉬운 팝업
          const alert = await this.alertCtrl.create({
            header: '안내',
            cssClass: 'hint-alert',
            // subHeader: 'Subtitle',
            message: '아쉽게도 당첨이 되지 않았습니다.<br>다음 기회에 참여바랍니다.',
            buttons: [
              {
                text: '확인',
                handler: () => {
                  console.log('Confirm Okay');
                  // this.showPg();
                  this.dismiss();
                  // this.router.navigate(['login']);
                },
              },
            ],
          });
          alert.present();
        }
      });
    } else {
      this.common.pleaseLogin();
    }
  }

  // async wonhagoModal(param) {
  //   const modal = await this.modalCtrl.create({
  //     component: ShopDetailPage,
  //     componentProps: param,
  //   });
  //   modal.onDidDismiss().then((result) => {
  //     console.log(['onDidDismiss', result]);
  //   });
  //   return await modal.present();
  // }

  ngOnDestroy() {
    // this.backbuttonSubscription.unsubscribe();
  }

}
