import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NaverService {
  status: string;
  constructor() { }

  getStatus() {
    return this.status;
  }

  setStatus(status) {
    this.status = status;
  }
}
