import { Component, OnInit } from '@angular/core';
import {NaverService} from '../../services/naver.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RestService} from '../../api/rest.service';
import {Events} from '@ionic/angular';
declare let naver_id_login: any;
declare let naver: any;
@Component({
  selector: 'app-naver',
  templateUrl: './naver.component.html',
  styleUrls: ['./naver.component.scss'],
})
export class NaverComponent implements OnInit {
  naverLogin: any;
  constructor(private naverService: NaverService,
              private route: ActivatedRoute,
              private rest: RestService,
              private events: Events,
              private router: Router,
              ) {
    // this.naverLogin = new naver_id_login('Tr8ZV5odm0nygv7qmjhq', 'http://localhost:8100/naver');
    this.naverLogin = new naver.LoginWithNaverId({
      clientId: 'Tr8ZV5odm0nygv7qmjhq',
      callbackUrl: 'http://localhost:8100/naver',
      // callbackUrl: 'http://localhost:3003/oauth',
      callbackHandle: true,
    });
    this.naverLogin.init();
    // this.route.queryParams.subscribe((params) => {
    //   console.log(['params', params]);
    //   alert(params.status);
    //   // alert(params.code);
    //   const apiUrl = 'https://nid.naver.com/oauth2.0/token?grant_type=authorization_code&client_id=' +
    //             'Tr8ZV5odm0nygv7qmjhq' + '&client_secret=' + 'Tpj01snn5J' + '&redirect_uri=https://logs.wonhago.com/oauth&code=&state=' + params.status;
    //   this.rest.getNaverLogin(apiUrl).then((res) => {
    //     console.log(['getNaverLogin', res]);
    //   });
    //
    //
    // });
    // console.log(['naver component', this.naverService.getStatus()]);
    // alert(this.naverService.getStatus());
    // alert(this.naverLogin);
    // this.naverLogin.get_naver_userprofile(this.naverProfile);

  }

  ngOnInit() {
    this.naverLogin.getLoginStatus((res) => {
      alert(res);
      if (res) {
        alert(this.naverLogin.user.getEmail());
        this.events.publish('naver:login', {success: true});
        // window.close();
        console.log(['네이버 메일', this.naverLogin.user.getEmail()]);
        this.router.navigate(['home']);

      }
      console.log(['res', res]);
    });
    console.log(['init', this.naverLogin.user]);

    // console.log(['naverProfile', this.naverLogin.getLoginStatus]);
    // alert(this.naverService.getStatus());

  }

  naverProfile() {
    console.log(['naverProfile', this.naverLogin]);
  }

}
