import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.page.html',
  styleUrls: ['./terms.page.scss'],
})
export class TermsPage implements OnInit {

  isModal = false;
  constructor(private location: Location,
              private modalCtrl: ModalController,
              private storage: Storage,
              private router: Router,
              ) {
    this.storage.get('modal-user').then(res => {
      console.log(['storage', res]);
      if (res) {
        this.isModal = true;
      } else {
        this.isModal = false;
      }
    });
  }

  ngOnInit() {

  }

  goNext() {
    this.router.navigate(['register']);
  }

  goBack() {
    this.location.back();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
