// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCeNTp2vMLTJf8KvypbIvjxvDM9D3bsaXo',
    authDomain: 'wonhago.firebaseapp.com',
    databaseURL: 'https://wonhago.firebaseio.com',
    projectId: 'wonhago',
    storageBucket: 'wonhago.appspot.com',
    messagingSenderId: '532742179911',
    appId: '1:532742179911:web:682b1a68dfc80103b07562',
    measurementId: 'G-YDECMYG2MC',
    vapidKey: 'BH9Z2BNo_6Ok2pqaP50H48QL7PuMMi5_iw2lawxHPt2IKtiNsrKKRUVqoRdlJvzoStiiB1eWLbMo6XLd_ALCm5k',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
