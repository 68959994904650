import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { MenuPage } from './menu.page';
import {DealPageModule} from '../user/deal/deal.module';
import {FindComponent} from '../components/find/find.component';
import {ShareComponent} from '../components/share/share.component';
import {NaverComponent} from '../components/naver/naver.component';
import {AuthGuardService} from '../services/auth-guard.service';

const routes: Routes = [
  // { path: '', component: MenuPage, loadChildren: '../intro/intro.module#IntroPageModule' },
  { path: 'login', loadChildren: '../login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: '../register/register.module#RegisterPageModule' },
  { path: 'home', component: MenuPage, loadChildren: () => import('../home/home.module').then( m => m.HomePageModule)},
  { path: 'detail', component: MenuPage, loadChildren: '../detail/detail.module#DetailPageModule' },
  { path: 'goods', component: MenuPage, loadChildren: '../goods/goods.module#GoodsPageModule' },
  { path: 'goods/:goods_code', component: MenuPage, loadChildren: '../goods/goods.module#GoodsPageModule' },
  { path: 'special/:goods_code', component: MenuPage, loadChildren: '../goods/goods.module#GoodsPageModule' },
  { path: 'terms', component: MenuPage, loadChildren: '../terms/terms.module#TermsPageModule' },
  { path: 'privacy', loadChildren: '../privacy/privacy.module#PrivacyPageModule' },
  { path: 'interval-info', loadChildren: '../interval-info/interval-info.module#IntervalInfoPageModule' },
  { path: 'regist-complete', component: MenuPage, loadChildren: '../regist-complete/regist-complete.module#RegistCompletePageModule' },
  { path: 'video-test', component: MenuPage, loadChildren: '../video-test/video-test.module#VideoTestPageModule' },
  { path: 'deal', canActivate: [AuthGuardService], component: MenuPage, loadChildren: '../user/deal/deal.module#DealPageModule' },
  { path: 'check-out', canActivate: [AuthGuardService],
    component: MenuPage, loadChildren: '../user/check-out/check-out.module#CheckOutPageModule' },
  { path: 'find', component: FindComponent, pathMatch: 'full' },
  { path: 'share', component: ShareComponent, },
  { path: 'naver', component: NaverComponent, },
  // { path: '', loadChildren: './comming-soon/comming-soon.module#CommingSoonPageModule' },
  { path: '', redirectTo: 'home', pathMatch: 'full', },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MenuPage, FindComponent, ShareComponent, NaverComponent]
})
export class MenuPageModule {}
