import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Events, Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {BehaviorSubject} from 'rxjs';
import {RestService} from '../api/rest.service';
import {CommonService} from './common.service';
import {UserService} from './user.service';

const TOKEN_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  authenticationStatus = new BehaviorSubject(false);

  constructor(private storage: Storage,
              private plt: Platform,
              private rest: RestService,
              private router: Router,
              private common: CommonService,
              private userService: UserService,
              private events: Events,

  ) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  login(data) {
    return this.storage.set(this.common.authKey, data).then((res) => {
      console.log(['login res', res]);
      this.common.isLoggedIn = true;
      this.common.isUser = true;
      this.rest.loginUser = res;
      this.userService.setUser(res[0]);
      this.authenticationStatus.next(true);

      // 외부 QR찾은 경우
      this.storage.get(this.common.qrKey).then((result) => {
        console.log(['find after qr', result]);

        if (result) {
          const param = {
            user_code : this.rest.loginUser[0].user_code,
            event_hash : result.a,
            block_hash : result.b,
            team_check : result.c,
            block_level : result.d,
            score: 0,
          };
          // 처리 후 디테일 페이지로 이동
          this.common.findBlock(param);
          this.storage.remove(this.common.qrKey);
        }

      });

      // 초대 회원가입 확인
      this.storage.get(this.common.inviteKey).then((result) => {
        console.log(['inviteKey', result]);

        if (result) {
          const param = {
            user_code: result.user_code,
            event_hash: result.event_hash,
            block_hash: result.block_hash,
            share_user: result.share_user,
          };
          this.common.findBlock(param);
        }

      });

      this.events.publish('user:login');

      this.router.navigateByUrl('/');
    });
  }

  logout() {
    console.log(['logout']);
    return this.storage.remove(this.common.authKey).then(() => {
      console.log(['logout remove']);
      this.rest.loginUser = null;
      this.common.isLoggedIn = false;
      this.userService.setUser(null);
      this.authenticationStatus.next(false);
      this.events.publish('user:logout');
    });
  }

  isAuthenticated() {
    return this.authenticationStatus.value;
  }


  checkToken() {
    console.log('checkToken');
    return this.storage.get(this.common.authKey).then(res => {
      if (res) {
        this.rest.loginUser = res;
        this.userService.setUser(res[0]);
        this.authenticationStatus.next(true);
      }
    });
  }
}
