import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {MenuPage} from './menu/menu.page';
import {FindComponent} from './components/find/find.component';
import {AuthGuardService} from './services/auth-guard.service';
import {ShareComponent} from './components/share/share.component';
import {NaverComponent} from './components/naver/naver.component';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  // { path: 'menu', loadChildren: './menu/menu.module#MenuPageModule' },
  // { path: '', loadChildren: './menu/menu.module#MenuPageModule' },
  //  routing 을 사용하는 경우
  { path: 'video-info', loadChildren: './video-info/video-info.module#VideoInfoPageModule' },
  { path: 'purchase-list', loadChildren: './purchase/purchase-list/purchase-list.module#PurchaseListPageModule' },

  { path: '', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule) },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  // { path: 'home', loadChildren: () => import('../home/home.module').then( m => m.HomePageModule)},
  { path: 'detail', loadChildren: './detail/detail.module#DetailPageModule' },
  { path: 'event/:event_code', loadChildren: './detail/detail.module#DetailPageModule' },
  { path: 'goods', loadChildren: './goods/goods.module#GoodsPageModule' },
  { path: 'goods/:goods_code', loadChildren: './goods/goods.module#GoodsPageModule' },
  { path: 'special/:goods_code', loadChildren: './goods/goods.module#GoodsPageModule' },
  { path: 'terms', loadChildren: './terms/terms.module#TermsPageModule' },
  { path: 'privacy', loadChildren: './privacy/privacy.module#PrivacyPageModule' },
  { path: 'interval-info', loadChildren: './interval-info/interval-info.module#IntervalInfoPageModule' },
  { path: 'regist-complete', loadChildren: './regist-complete/regist-complete.module#RegistCompletePageModule' },
  { path: 'video-test', loadChildren: './video-test/video-test.module#VideoTestPageModule' },
  { path: 'deal', loadChildren: './user/deal/deal.module#DealPageModule' },
  { path: 'check-out',
    loadChildren: './user/check-out/check-out.module#CheckOutPageModule' },
  { path: 'find', component: FindComponent, pathMatch: 'full' },
  { path: 'share', component: ShareComponent, },
  { path: 'naver', component: NaverComponent, },
  { path: 'register-invite', loadChildren: './register-invite/register-invite.module#RegisterInvitePageModule' },
  { path: 'service-info', loadChildren: './service-info/service-info.module#ServiceInfoPageModule' },
  { path: 'event-list', loadChildren: './event/event-list/event-list.module#EventListPageModule' },
  { path: 'event-detail', loadChildren: './event/event-detail/event-detail.module#EventDetailPageModule' },
  { path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsPageModule' },
  { path: 'about-us', loadChildren: './about-us/about-us.module#AboutUsPageModule' },
  { path: 'service', loadChildren: './about-us/about-us.module#AboutUsPageModule' },
  { path: 'special', loadChildren: './about-us/about-us.module#AboutUsPageModule' },
  { path: 'menu/:menu_seq', loadChildren: './site-menu/site-menu.module#SiteMenuPageModule' },
  { path: 'test', loadChildren: './pages/test/test.module#TestPageModule' },

  // { path: 'intro-modal', loadChildren: './intro-modal/intro-modal.module#IntroModalPageModule' },
  // { path: 'header', loadChildren: './header/header.module#HeaderPageModule' },
  // { path: 'terms-modal', loadChildren: './terms-modal/terms-modal.module#TermsModalPageModule' },
  // { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  // { path: 'deal', loadChildren: './user/deal/deal.module#DealPageModule' },
  // { path: 'detail', loadChildren: './detail/detail.module#DetailPageModule' },
  // { path: 'goods', loadChildren: './goods/goods.module#GoodsPageModule' },
  // { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  // { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  // { path: 'terms', loadChildren: './terms/terms.module#TermsPageModule' },
  // { path: 'privacy', loadChildren: './privacy/privacy.module#PrivacyPageModule' },
  // { path: 'final-modal', loadChildren: './user/final-modal/final-modal.module#FinalModalPageModule' },
  // { path: 'interval-info', loadChildren: './interval-info/interval-info.module#IntervalInfoPageModule' },
  // { path: 'regist-complete', loadChildren: './regist-complete/regist-complete.module#RegistCompletePageModule' },
  // { path: 'find', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
