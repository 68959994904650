import {Component, OnInit} from '@angular/core';

import { Events, MenuController, ModalController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {RestService} from './api/rest.service';
import {AuthenticationService} from './services/authentication.service';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {CommonService} from './services/common.service';
import {Location} from '@angular/common';
import {Storage} from '@ionic/storage';
import {UserService} from './services/user.service';
import {ApiService} from "./services/api.service";
import * as uuid from 'uuid';
import {User} from "./data/user";
import {SiteInfoService} from "./services/site-info.service";
import {firebase} from '@firebase/app';
import {environment} from '../environments/environment';
import {NotificationsService} from './services/notifications.service';
import {IntroModalPage} from "./intro-modal/intro-modal.page";
declare let Kakao: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  loggedIn = false;
  darkClass = 'dark';
  dark = true;
  constructor(
    private events: Events,
    private platform: Platform,
    // private actionSheetCtrl: ActionSheetController,
    // private popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    private menu: MenuController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private apiService: ApiService,
    private rest: RestService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private notificationsService: NotificationsService,
    private storage: Storage,
    private common: CommonService,
    private userService: UserService,
    private siteInfoService: SiteInfoService,
  ) {

    // site 기본정보 가져오기
    this.rest.getSiteInfo({site_code: apiService.siteCode}).then( (res) => {
      console.log(['== getSiteInfo', res]);
      if (res[0].success) {
        // 다크 모드 사용시 변경됨.
        if (res[0].sites.use_dark ) {
          this.darkClass = 'dark';
        } else {
          this.darkClass = '';
        }
        siteInfoService.setSiteInfo(res[0].sites);
        siteInfoService.setSiteMenu(res[0].menus);
      }
    });

    this.storage.ready().then( () => {
      console.log(['ready storage', this.userService.getUser(), this.common.deviceKey]);

      this.getUUID().then((res) => {
        console.log(['getUUid', res]);
          // let user = new User();
          if (res) {
            // 기존 스토리지 삭제 후 재 생성
            if (!res.user_code) {
              console.log(['adf']);
              this.storage.remove(this.common.deviceKey).then((del) => {
                this.setUserDevice();
              });
            } else {
              console.log(['exist uuid', res]);
              // 사용자 정보 서버 확인
              this.rest.getDeviceUser(res).then( (info) => {
                console.log(['getDeviceUser', info]);

                if (info[0].success) {
                  // 가져온 후 storage에 저장한다.
                  let user = info[0].info;
                  this.setUserStorage(user);
                  this.userService.setUser(user);
                } else {
                  // 사용자가 없음 신규 생성
                  this.setUserDevice();
                }
              })

            }
          } else {
            // 없는 경우
            this.setUserDevice();
          }
          // this.userService.setUser(user);
        this.events.publish('user:init');
      });
    });

    if (this.apiService.useLogin) {
      storage.get(common.authKey).then( (res) => {
        console.log(['const storage', res]);
        if (res) {
          console.log(['res join_type', res[0].join_type]);
          let user = new User();
          user.user_code = res[0].user_code;
          user.user_name = res[0].user_name;
          user.user_mobile = res[0].user_mobile;
          user.user_email = res[0].user_email;
          this.userService.setUser(user);
          common.isLoggedIn = true;
        }
        this.initializeApp();
      });
    } else {
      this.initializeApp();
    }
  }

  setUserDevice() {
    const deviceId = uuid.v4();
    console.log(['setUserDevice', deviceId]);

    // 저장하고 db정보를 가져온다.
    this.rest.registerDevice({device_id: deviceId}).then( async (rs) => {
      console.log(['registerDevice', rs]);

      let deviceInfo = {};
      if (rs && rs[0].success) {
        deviceInfo = rs[0].info[0];
      }

      // 가져온 후 storage에 저장한다.
      await this.setUserStorage(deviceInfo);
    });
  }

  // 사용자 정보를 스토리지에 저장한다.
  async setUserStorage(info) {
    // 가져온 후 storage에 저장한다.
    await this.storage.set(this.common.deviceKey, info).then( (result) => {
      console.log(['set uuid ', result]);
      this.userService.setUser(result);
    })
  }


  initializeApp() {
    // this.platform.backButton.subscribe(() => {
    //   alert('sub back');
    // })
    this.platform.ready().then(() => {
      window.addEventListener('popstate', async () => {
        // alert(['popstatus']);
        console.log(['addEventListener popstate']);
        // window.history.pushState({}, '');
        // alert('popstate');
        try {
          const element = await this.modalCtrl.getTop();
          if (element) {
            window.history.pushState({}, '');
            element.dismiss();
            return;
          }
        } catch (error) {
          console.log(error);
        }

      });

      // 기본 api 정보 셋팅
      // const data = {
      //   kakaoKey: '96137e9e63a68aef5954d4b3eca17871',
      //   serverUrl: 'https://logs.wonhago.com',
      //   imageUrl: 'https://admin.wonhago.com',
      //   // replaceUrl: 'https://test.wonhago.com/find?',
      //   siteUrl: 'https://test.wonhago.com',
      //   bootpayKey: '5d6dd9c90627a80037aeca3d',
      //   companyName: '(주)부치',
      //   companyPhone: '010-6387-7509',
      //   companyNumber: '636-88-00748',
      //   companyAddress: '서울특별시 마포구 큰우물로 76, 4층',
      //   companyCopyright: 'BuchiGo.Inc All rights reserved.',
      //   ceoName: '대표 : 최문성',
      //   accessToken: 'abcd'
      // };
      // this.apiService.setData(data);

      Kakao.init('ab5f56a0685ab7ea77b4d66770b70916');
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      console.log(['init route path', this.activatedRoute]);

      if (this.location.path().indexOf('find') >= 0 || this.location.path().indexOf('block') >= 0 || this.location.path().indexOf('share') >= 0) {
        // this.authService.authenticationStatus.subscribe(state => {
        //   console.log(['direct authenticationStatus', state]);
        // });
      } else {
        // if (this.common.isLoggedIn) {
        //   this.router.navigate(['home']);
        // } else {
        //   this.router.navigate(['login']);
        // }
        // this.router.navigate(['home']);
      }
    });
  }

  async ngOnInit() {
    // this.listenForLoginEvents();
    // this.events.publish('page:init');
    console.log(['app.component init ']);
    firebase.initializeApp(environment.firebase);
    await this.notificationsService.init();

    // this.getUUID().then((res) => {
    //   console.log(['storage uuid', res]);
    //   // let user = new User();
    //   if (res) {
    //     // 있는 경우
    //     console.log(['exist uuid', res]);
    //     this.userService.setUser(res);
    //     // user.device_id = res;
    //
    //   } else {
    //     // 없는 경우
    //     const deviceId = uuid.v4();
    //
    //     // 저장하고 db정보를 가져온다.
    //     this.rest.registerDevice({device_id: deviceId}).then( async (rs) => {
    //       console.log(['registerDevice', rs]);
    //
    //       let deviceInfo = {};
    //       if (rs && rs[0].success) {
    //         deviceInfo = rs[0].info[0];
    //       }
    //
    //       // 가져온 후 storage에 저장한다.
    //       await this.storage.set(this.common.deviceKey, deviceInfo).then( (result) => {
    //         console.log(['set uuid ', result]);
    //         this.events.publish('user:init');
    //         this.userService.setUser(result);
    //         // user.device_id = result;
    //         // storage에 저장하고 db에 저장한다.
    //         // this.rest.registerDevice({device_id: result}).then( (rs) => {
    //         //   console.log(['registerDevice', rs]);
    //         // });
    //       })
    //     });
    //
    //
    //   }
    //   // this.userService.setUser(user);
    // });
  }

  ngAfterViewInit() {
    this.platform.ready().then(async () => {
      await this.notificationsService.requestPermission();
    });
  }

  async getUUID() {
    console.log(['getUUid', this.common.deviceKey]);
    return await this.storage.get(this.common.deviceKey);
  }

  ionViewWillEnter() {
    console.log(['app component ionViewWillEnter']);
  }

  // checkLoginStatus() {
  //   return this.userData.isLoggedIn().then(loggedIn => {
  //     return this.updateLoggedInStatus(loggedIn);
  //   });
  // }
}
