import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SiteInfoService {

  siteInfo = {
    site_logo: '',
    company_name: '',
    ceo_name: '',
    company_phone: '',
    company_manager: '',
    company_number: '',
    company_address: '',
    company_copyright: '',
    company_email: '',
    company_fax: '',
    latitude: 0,
    longitude: 0
  };

  siteMenu = [{
    menu_seq: '',
    menu_name: ''
  }];

  constructor() { }

  getSiteInfo() {
    return this.siteInfo;
  }

  setSiteInfo(info) {
    this.siteInfo = info;
  }

  getSiteMenu() {
    return this.siteMenu;
  }

  setSiteMenu(menu) {
    this.siteMenu = menu;
  }
}
