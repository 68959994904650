import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  isDebug =  false;    // 디버그 모드
  useLogin = false;  //로그인 사용여부
  public siteCode = 'test';  // 사이트 구분 코드
  public version = '200222.01';
  data = {
    kakaoKey: '96137e9e63a68aef5954d4b3eca17871',
    serverUrl: 'https://logs.wonhago.com',
    imageUrl: 'https://admin.wonhago.com',
    // replaceUrl: 'https://test.wonhago.com/find?',
    siteUrl: 'https://test.wonhago.com',
    bootpayKey: '5d6dd9c90627a80037aeca3d',
    companyName: '(주)부치고',
    companyPhone: '010-6387-7509',
    companyNumber: '636-88-00748',
    companyAddress: '서울특별시 마포구 큰우물로 76, 4층',
    companyCopyright: 'BuchiGo.Inc All rights reserved.',
    ceoName: '대표 : 최문성',
    accessToken: 'abcd'
  };
  companyinfo = {
    company_name: '(주)부치고',
    ceo_name: '대표 : 최문성',
    company_phone: '010-6387-7509',
    company_number: '636-88-00748',
    company_address: '서울특별시 마포구 큰우물로 76, 4층',
    company_copyright: '2019 BuchiGo.Inc All rights reserved.'
  }
  accessToken = 'abcd';

  constructor() {
    if (this.isDebug) {
      this.data.serverUrl = 'https://logswonhago.respectjob.com';
      this.data.imageUrl = 'https://adminwonhago.respectjob.com';
      // this.data.replaceUrl = 'https://pwawonhago.respectjob.com/find?';
      this.data.siteUrl = 'https://pwawonhago.respectjob.com';
    }
  }ㅁ

  getApiUrl() {
    return this.data;
  }

  setData(data) {
    this.data = data;
  }


}
