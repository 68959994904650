import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {RestService} from '../api/rest.service';
import {AuthenticationService} from '../services/authentication.service';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.page.html',
  styleUrls: ['./terms-modal.page.scss'],
})
export class TermsModalPage implements OnInit {
  isModal = false;
  modalUser: any;

  constructor(private authService: AuthenticationService,
              private modalCtrl: ModalController,
              private storage: Storage,
              private rest: RestService,
              ) {
    this.storage.get('modal-user').then(res => {
      console.log(['storage', res]);
      if (res) {
        this.isModal = true;
        this.modalUser = res;
      } else {
        this.isModal = false;
        this.modalUser = null;
      }

    }, (fail) => {
      console.log(['storage fail', fail]);
    });
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
    this.storage.set('modal-user', null);
  }

  goComplete() {
    console.log(['goComplete', this.modalUser]);
    const param = {
      social_id : this.modalUser.id,
      email : this.modalUser.kakao_account.email,
      nickname : this.modalUser.properties.nickname,
      user_picture : this.modalUser.properties.thumbnail_image,
      join_type: 'kakao'
    }

    this.rest.kakaoSignup(param).then(res => {
      console.log(['kakaoSignup', res]);
      if (res && res[0].success) {
        this.modalCtrl.dismiss();
        this.authService.login(res[0].user);
      } else {
        this.rest.showToast('회원가입에 실패했습니다.\n다시 시도해주세요.', 3000);
      }

      this.storage.set('modal-user', null);
    });
    // 소셜 가입 완료
  }

}
