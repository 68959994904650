import { Injectable } from '@angular/core';
import {PaymentInfo} from '../data/paymentInfo';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  paymentInfo: PaymentInfo;
  constructor() { }

  getPayment() {
    return this.paymentInfo;
  }

  setPayment(paymentInfo: PaymentInfo) {
    console.log(['setPayment', paymentInfo]);
    this.paymentInfo = paymentInfo;
  }
}
