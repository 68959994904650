// import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-expandable',
  templateUrl: './expandable.component.html',
  styleUrls: ['./expandable.component.scss'],
})
export class ExpandableComponent implements OnInit {
  // @ViewChild('expandWrapper', {read: ElementRef }) expandWrapper: ElementRef;
  // @Input('expanded') expanded: boolean;
  // @Input('expandHeight') expandHeight: '150px';
  // constructor(public renderer: Renderer2) { }
  // @Input
  constructor() { }

  ngOnInit() {}

  // ngAfterViewInit() {
  //   this.renderer.setStyle(this.expandWrapper.nativeElement, 'max-height', this.expandHeight);
  // }

}
