import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  data = {
    user_code : '',
    event_hash : '',
    block_hash : '',
  }
  constructor() { }

  getInvite() {
    return this.data;
  }

  setInvite(param) {
    this.data = param;
  }
}
