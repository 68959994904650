export class User {
    // tslint:disable-next-line:variable-name
    user_code = '';
    // tslint:disable-next-line:variable-name
    user_name = '';
    // tslint:disable-next-line:variable-name
    user_email = '';
    // tslint:disable-next-line:variable-name
    user_mobile = '';
    device_id = '';
    zipCode = '';
    address = '';
    // tslint:disable-next-line:variable-name
    address_etc = '';
    nickname = '';
    point = 0;
}
