import {User} from './user';

export class Reward {
    event_hash: string;
    delivery_message: string;
    my_rank: number;
    reward: string;
    reward_seq: number;
    reward_option: string;
    reward_option_seq = '11';
    reward_type: string;
    reward_list: any;
    userInfo: User;
}
