import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {IonicModule} from '@ionic/angular';
import {FooterComponent} from './footer/footer.component';
import {ExpandableComponent} from './expandable/expandable.component';
import {ModalHeaderComponent} from './modal-header/modal-header.component';
import {FindComponent} from './find/find.component';
import {VideoAdComponent} from './video-ad/video-ad.component';

@NgModule({
  declarations: [FooterComponent, ExpandableComponent, ModalHeaderComponent, HeaderComponent, VideoAdComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [FooterComponent, ExpandableComponent, ModalHeaderComponent, HeaderComponent, VideoAdComponent],
})
export class ShareComponentsModule { }
