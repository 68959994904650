import { Component, OnInit } from '@angular/core';
import {Events, MenuController, NavController} from '@ionic/angular';
import {NavigationExtras, Router} from '@angular/router';
import {Location} from '@angular/common';
import {UserService} from "../../services/user.service";
import {ApiService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {User} from "../../data/user";
import {SiteInfoService} from "../../services/site-info.service";
import {RestService} from "../../api/rest.service";
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  title = '';
  isMenu = true;
  isLoggedIn = false;
  useLogin = false;
  imageUrl = '';
  userInfo : User;
  companyinfo = {
      site_logo : '',
      company_name: '(주)부치고',
      company_phone: '010-6387-7509',
      company_number: '636-88-00748',
      company_address: '서울특별시 마포구 큰우물로 76, 4층',
      company_copyright: 'BuchiGo.Inc All rights reserved.',
      ceo_name: '대표 : 최문성',
  };

  siteMenus = [];

  contents = [
    {
        title : '회사소개',
        banner : ''
    },
    {
        title : '주요서비스',
        banner : ''
    },
    {
        title : 'UWaa특징',
        banner : ''
    }
  ];
  content: any;

  constructor(private apiService: ApiService,
              private authService: AuthenticationService,
              private events: Events,
              private menu: MenuController,
              private navCtrl: NavController,
              private location: Location,
              private userService: UserService,
              private rest: RestService,
              private router: Router,
              private siteInfoService: SiteInfoService,
  ) {
      console.log(['==== header comp const ', this.router.url, this.userInfo, this.userService.getUser()]);
      this.imageUrl = this.rest.imageUrl;
      const url = this.router.url;
      this.getUserInfo();
      setTimeout(() => {
          this.companyinfo = this.siteInfoService.getSiteInfo();
          this.siteMenus = this.siteInfoService.getSiteMenu();

      }, 300);

      this.events.subscribe('user:init', () => {
          console.log(['listenForLoginEvents']);
          this.isLoggedIn = true;
          this.userInfo = this.userService.getUser();
          // this.userInfo = this.rest.loginUser
      });



      if (!this.userService.getUser().user_code) {
          setTimeout(() => {
              console.log('2 sec after call');
              this.getUserInfo();;
          }, 1000);
      }

      this.useLogin = this.apiService.useLogin;
      // this.menu.enable(true);

      if (url.indexOf('login') > -1) {
          this.title = '로그인';
          this.isMenu = false;
      } else if (url.indexOf('goods') > -1) {
          this.title = '상품 보기';
      } else if (url.indexOf('detail') > -1) {
          this.title = '이벤트 보기';
      } else if (url.indexOf('terms') > -1 || url.indexOf('register') > -1 || url.indexOf('regist-complete') > -1) {
          this.title = '회원가입';
          this.isMenu = false;
      } else if (url.indexOf('invite') > -1) {
          this.title = '초대가입';
          this.isMenu = false;
      }
  }

  ionViewWillEnter() {
      console.log(['========= header comp ionViewWillEnter', this.router.getCurrentNavigation()]);

  }

  ngOnInit() {
    console.log(['header comp ngOnInit', this.location.path(), this.userService.getUser()]);
  }

  async getUserInfo() {
      console.log(['header getUserInfo', this.userService.getUser()]);
      this.userInfo = this.userService.getUser();
  }

  goHome() {
    // this.router.navigate(['home']);
    console.log([this.location.path()]);

    // if (this.location.path() === '/home') {
    //   this.menu.enable(true);
    //
    //   this.location.back();
    // } else {
    //   this.menu.enable(true);
    //   this.router.navigate(['home']);
    // }

    // this.router.navigate(['home']);

    this.navCtrl.navigateRoot('/');
    this.closeMenu();
    // this.menu.toggle();
  }

  goMenu(val) {
      this.navCtrl.navigateForward('menu/' + val);
  }

  serviceInfo() {
      this.navCtrl.navigateForward('service-info');
      this.closeMenu();
  }

  eventList() {
      this.navCtrl.navigateForward('event-list');
      this.closeMenu();
  }

  goFaq() {
      this.closeMenu();
  }

  openMenu() {
      console.log(['openMenu']);
      // this.menu.enable(true);
      // this.menu.open();
      $('.sm-horizontal').css('left', '0px');
  }

  closeMenu() {
      console.debug(['close menu', this.isLoggedIn, this.userInfo, window.innerWidth]);
      if (window.innerWidth < 1200) {
          $('.sm-horizontal').css('left', '-410px');
      }
  }

  goLogin() {
      console.log('gologin');
      // this.menuCtrl.close();
      // this.router.navigate(['login']);
      this.navCtrl.navigateForward('login');
      this.closeMenu();
  }

  goAboutUs(type) {
      switch (type) {
          case 'intro':
              // this.content = this.contents[0];
              this.navCtrl.navigateForward('about-us');
              break;
          case 'service':
              this.navCtrl.navigateForward('service');
              break;
          case 'special':
              this.navCtrl.navigateForward('special');
              break;
      }

      this.closeMenu();
  }

  goContactUs() {
      this.navCtrl.navigateForward('contact-us');
      this.closeMenu();
  }

  goLogout() {
      // this.loginUser = null;
      // this.menuCtrl.close();
      this.authService.logout();
      this.closeMenu();
      // this.router.navigate(['login']);
  }

  toggleMenu() {

    if (this.location.path() === '/home') {

      this.menu.enable(true);
      this.menu.open();
    } else {
      this.menu.toggle();
    }

  }

}
