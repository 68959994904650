import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {DealPageModule} from './user/deal/deal.module';
import {FinalModalPageModule} from './user/final-modal/final-modal.module';
import {IonicStorageModule} from '@ionic/storage';
import {HttpClientModule} from '@angular/common/http';
import {PrivacyPageModule} from './privacy/privacy.module';
import {TermsModalPageModule} from './terms-modal/terms-modal.module';
import {InfoModalPageModule} from './user/info-modal/info-modal.module';
import {IntroModalPageModule} from './intro-modal/intro-modal.module';
import {VideoInfoPageModule} from './video-info/video-info.module';
import {MenuPageModule} from './menu/menu.module';
import {IntervalInfoPageModule} from "./interval-info/interval-info.module";
import {DeviceDetectorModule} from "ngx-device-detector";
import {NgSelect2Module} from "ng-select2";
import {VideoTestPageModule} from "./video-test/video-test.module";
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [
    AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    DealPageModule,
    FinalModalPageModule,
    PrivacyPageModule,
    TermsModalPageModule,
    InfoModalPageModule,
    IntroModalPageModule,
    VideoInfoPageModule,
    VideoTestPageModule,
    IntervalInfoPageModule,
    MenuPageModule,
    NgSelect2Module,
    DeviceDetectorModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
