import { Injectable } from '@angular/core';
import {Reward} from '../data/reward';

@Injectable({
  providedIn: 'root'
})
export class RewardService {
  reward: Reward;
  constructor() { }

  getReward(): Reward {
    return this.reward;
  }

  setReward(reward: Reward) {
    this.reward = reward;
  }
}
