import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertController, Events, MenuController, ModalController, NavController} from '@ionic/angular';
import {RestService} from '../api/rest.service';
import {NavigationExtras, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {Storage} from '@ionic/storage';
import {CommonService} from '../services/common.service';
import {FinalModalPage} from '../user/final-modal/final-modal.page';
import {UserService} from '../services/user.service';
import {User} from '../data/user';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit, OnDestroy {
  loggedIn = false;
  loginUser = {
    nickname: '',
    point: 0,
  };
  eventList: any = [];
  purchaseList: any = [];
  imageUrl: any;
  user: User;
  version = '1.0';

  constructor(private authService: AuthenticationService,
              private alertCtrl: AlertController,
              private events: Events,
              private common: CommonService,
              private menuCtrl: MenuController,
              private navCtrl: NavController,
              private rest: RestService,
              private router: Router,
              private storage: Storage,
              private modalCtrl: ModalController,
              private userService: UserService,
              ) {
    console.log('===================');
    console.log(['menu login', this.rest.loginUser, this.loggedIn, this.userService.getUser()]);
    this.user = this.userService.getUser();

    if (this.user) {
        this.loggedIn = true;
    }
    this.version = this.rest.version;
    console.log(['set this.user', this.user]);

    // if (this.user) {
    //   this.loggedIn = true;
    //   this.user.point = 0;
    //   this.getMyList(this.user);
    // }

    this.menuCtrl.isOpen().then( () => {
      console.log(['menu open', this.loggedIn]);
    //   // if (this.loggedIn) {
    //   //   this.getMyList(this.loginUser);
    //   // }
    //   // this.getMyList(this.loginUser);
    //
    //   this.storage.get(this.common.authKey).then((res) => {
    //     console.log(['menu storage', res]);
    //     if (res) {
    //       this.updateLoggedInStatus(true);
    //       this.loginUser = res[0];
    //       this.getMyList(this.loginUser);
    //     }
    //   });
    });

  }

  async ngOnInit() {
    this.imageUrl = this.rest.imageUrl;

    // if (!this.loggedIn) {
    //   this.storage.get(this.common.authKey).then((res) => {
    //     if (res) {
    //       this.updateLoggedInStatus(true);
    //       this.loginUser = res[0];
    //     }
    //   });
    // } else {
    //   this.getMyList(this.loginUser);
    // }
    this.listenForLoginEvents();

    // console.log(['menu user ', this.loginUser]);
  }

  ionViewWillEnter() {
      this.user = this.userService.getUser();
      if (this.user) {
          this.loggedIn = true;
      }
  }

  listenForLoginEvents() {
    this.events.subscribe('purchase:success', () => {
      console.log(['purchase events mylist call']);
      // this.getMyList(this.user);
    });

    this.events.subscribe('user:login', () => {
      console.log(['listenForLoginEvents', 'login', this.user, this.userService.getUser()]);
      this.user = this.userService.getUser();
      this.menuCtrl.enable(true);
      this.setLoginUser();
      this.updateLoggedInStatus(true);
    });

    this.events.subscribe('user:signup', () => {
      console.log(['listenForLoginEvents', 'signup']);
      this.updateLoggedInStatus(true);
    });

    this.events.subscribe('user:logout', () => {
      console.log(['listenForLoginEvents', 'logout', this.userService.getUser(), this.rest.loginUser]);
      this.updateLoggedInStatus(false);
    });

    // this.events.subscribe('paymentSuccess', (res) => {
    //   console.log(['event pay success', res]);
    // });

    // console.log(['is loggedin', this.loggedIn]);
    // this.events.subscribe('page:init', () => {
    //   console.log(['init event', 'init']);
    //   this.getMyList(this.loginUser);
    // });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  async setLoginUser() {
    // this.getMyList(this.user);
    // this.storage.get(this.common.authKey).then((res) => {
    //   console.log(['menu setLoginUser', res]);
    //   this.loginUser = res[0];
    //   this.userService.setUser(res[0]);
    //
    //
    // });
  }

  goDetail(adHash) {
    if (this.common.isLoggedIn) {
      const navigationExtras: NavigationExtras = {
        state: {
          data: {
            id: adHash,
            is_common: false,
          }
        },
      };
      this.router.navigate(['detail'], navigationExtras);
      // this.menuToggle();
    } else {
      this.common.pleaseLogin();
    }
  }

  async getMyList(user) {
    console.log(['async getMyList', user, this.user]);
    await this.rest.getMyList(user).then((res) => {
      console.log(['res getMylist', res]);

      this.user.point = res[0].myPoint;
      this.eventList = res[0].eventList;
      this.purchaseList = res[0].purchaseList;
      console.log(['eventList', this.eventList, this.purchaseList]);
    });
  }

  menuToggle() {
    this.menuCtrl.toggle();
  }

  async pointInfo() {
    const alert = await this.alertCtrl.create({
      header: '포인트',
      cssClass: 'hint-alert',
      // subHeader: 'Subtitle',
      message: '이벤트 조각을 다 모으면 보상포인트 드립니다.',
      buttons: [
        {
          text: '확인',
          handler: () => {
            console.log('Confirm Okay');
            // this.showPg();
          },
        },
      ],
    });
    alert.present();
  }

  goLogin() {
    console.log('gologin');
    this.menuCtrl.close();
    this.router.navigate(['login']);
  }

  goLogout() {
    // this.loginUser = null;
    this.menuCtrl.close();
    this.authService.logout();
    this.router.navigate(['login']);
  }

  async finalModal(param) {
    const modal = await this.modalCtrl.create({
      component: FinalModalPage,
      componentProps: param,
    });
    modal.onDidDismiss().then((result) => {
      console.log(['onDidDismiss', result]);
    });
    return await modal.present();
  }

  async goFinal() {
    const modal = await this.modalCtrl.create({
      component: FinalModalPage,
      componentProps: this.user,
    });
    modal.onDidDismiss().then((result) => {
      console.log(['onDidDismiss', result]);
    });
    return await modal.present();
  }

  goPurchase() {
    this.navCtrl.navigateForward('purchase-list');
  }

  ngOnDestroy() {
    console.log(['menu ngOnDestroy']);
    this.menuCtrl.enable(true);
    this.events.unsubscribe('purchase:success');
    this.events.unsubscribe('user:login');
    this.events.unsubscribe('user:signup');
    this.events.unsubscribe('user:logout');
  }

}
