import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {User} from '../../data/user';
import {UserService} from '../../services/user.service';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {FormBuilder, FormGroup} from '@angular/forms';
import { postcode } from 'src/assets/js/postcode.js';
import {RestService} from '../../api/rest.service';
import {Reward} from '../../data/reward';
import {RewardService} from '../../services/reward.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.page.html',
  styleUrls: ['./info-modal.page.scss'],
})
export class InfoModalPage implements OnInit {
  userInfo: User;
  frm: FormGroup;

  reward: Reward;
  rewardList: any;
  eventHash: any;

  @ViewChild('daum_popup', { read: ElementRef, static: true }) popup: ElementRef;
  constructor(private alertCtrl: AlertController,
              private modalCtrl: ModalController,
              private navParams: NavParams,
              private rest: RestService,
              private userService: UserService,
              private rewardService: RewardService,
              private formBuilder: FormBuilder,
              private renderer: Renderer2,
              ) {
    this.userInfo = this.userService.getUser();
    this.reward = this.rewardService.getReward();
    this.eventHash = this.navParams.data.event_hash;
    console.log(['info reward', this.reward, this.navParams.data]);

    if (this.reward.reward_type === 'REWARD_TYPE03') {
      // 경품 목록을 가져온다.
      const param = { event_hash: this.reward.event_hash}
      this.rest.getRewardList(param).then( (res) => {
        console.log(['getRewardList', res]);
        if (res && res[0].success) {
          this.rewardList = res[0].reward_list;
        }
      });
    }
    // this.reward.userInfo = this.userInfo;
    // this.userInfo.event_hash = '2876b66d47243da0bcc501d1577ac0867f8c3a0539a0a55287a55ca3ccfc170a';
    // this.userInfo.reward_option_seq = '11';
    // this.rest.getInfoCheck(this.userInfo).then( async (res) => {
    //   console.log(['res', res]);
    //
    //   if (res && res[0].success) {
    //     this.rewards.reward = res[0].reword_item;
    //     this.rewards.reward_seq = res[0].reword_seq;
    //     this.rewards.my_rank = res[0].my_rank;
    //     // this.userService.setUser(userInfo2);
    //   }
    // });
  }

  async ngOnInit() {
    this.userInfo = this.userService.getUser();
    console.log(['userInfo', this.userInfo]);
    this.frm = this.formBuilder.group({
      zipCode: [''],
      address: [''],
      addressEtc  : ['']
    });
    console.log(['info user', this.userInfo]);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  complete() {
    console.log(['complete', this.userInfo]);

    if (!this.userInfo.user_name) {
      this.rest.showToast('수령인을 입력하세요.', 2000);
      return;
    }

    if (!this.userInfo.user_mobile) {
      this.rest.showToast('연락처를 입력하세요.', 2000);
      return;
    }

    if (!this.userInfo.zipCode) {
      this.rest.showToast('우편번호 찾기를 통해 주소를 입력하세요.', 2000);
      return;
    }

    if (!this.userInfo.address) {
      this.rest.showToast('우편번호 찾기를 통해 주소를 입력하세요.', 2000);
      return;
    }
    console.log(['reward items', this.reward]);

    this.rest.setRewardInfo(this.reward).then( async (res) => {
      if (res && res[0]) {
        if (res[0].success) {
          const alert = await this.alertCtrl.create({
            header: '안내',
            cssClass: 'hint-alert',
            // subHeader: 'Subtitle',
            message: '정보입력이 완료되었습니다.<br>담당자 확인 후 경품배송이 진행됩니다.',
            buttons: [
              {
                text: '확인',
                handler: () => {
                  this.dismiss();
                },
              },
            ],
          });
          alert.present();
        } else {
          const alert = await this.alertCtrl.create({
            header: '안내',
            cssClass: 'hint-alert',
            // subHeader: 'Subtitle',
            message: res[0].message,
            buttons: [
              {
                text: '확인',
                handler: () => {
                  // this.dismiss();
                },
              },
            ],
          });
          alert.present();
        }
      }
    });
  }

  openDaumPopup() {
    postcode(this.renderer, this.popup.nativeElement, data => {
      this.frm.controls.zipCode.setValue(data.zonecode);
      this.frm.controls.address.setValue(data.address);
      console.log(data);
    });
  }

  closeDaumPopup() {
    this.renderer.setStyle(this.popup.nativeElement, 'display', 'none');
  }

}
