import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {RestService} from "../api/rest.service";

@Component({
  selector: 'app-interval-info',
  templateUrl: './interval-info.page.html',
  styleUrls: ['./interval-info.page.scss'],
})
export class IntervalInfoPage implements OnInit {
  img = null;
  url = null;
  title = '';
  constructor(private navParams: NavParams,
              private rest: RestService,
              private modalCtrl: ModalController) { }

  ngOnInit() {
    this.title =  this.navParams.get('title');
    this.img = this.rest.imageUrl+'/storage'+this.navParams.get('image');
    this.url = this.navParams.get('targetUrl');
  }

  goBack() {
    this.modalCtrl.dismiss();
  }

}
