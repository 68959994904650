import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Platform, ToastController} from '@ionic/angular';
import { BehaviorSubject} from 'rxjs';
import {CommonService} from '../services/common.service';
import {ApiService} from "../services/api.service";

@Injectable({
  providedIn: 'root',
})
export class RestService {

  headers: any;
  // isDebug = true;
  isPayTest = false;

  serverUrl: string;
  imageUrl: string;
  // replaceUrl = 'http://localhost:3001/block?';
  siteUrl: string;
  version = '11.22.01';
  appPlatform = '';
  loginUser: any;
  data: any;
  siteCode: string;
  kakaoAuthUrl = 'https://kauth.kakao.com';

  kakaoKey = {
    clientID : '',
    clientSecret: '', // clientSecret을 사용하지 않는다면 넘기지 말거나 빈 스트링을 넘길 것
    callbackURL : ''
  };
  bootpayKey: string;

  constructor(public apiService: ApiService,
              private httpClient: HttpClient,
              private platform: Platform,
              public toast: ToastController,
              ) {
    // this.headers.set('', '');
    this.platform.platforms();
    this.headers = new HttpHeaders(
        {'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.apiService.accessToken
        }
    );

    if (this.platform.is('android')) {
      this.appPlatform = 'android';
    } else if (this.platform.is('ios')) {
      this.appPlatform = 'ios';
    }

    const apiData = apiService.getApiUrl();
    this.kakaoKey.clientID = apiData.kakaoKey;
    this.siteCode = this.apiService.siteCode;
    this.version = this.apiService.version;
    this.serverUrl = apiData.serverUrl;
    this.imageUrl = apiData.imageUrl;
    // this.replaceUrl = apiData.replaceUrl;
    this.siteUrl = apiData.siteUrl;
    this.bootpayKey = apiData.bootpayKey;

    // console.log(['isDebug', this.isDebug]);

    // if (this.isDebug) {
    //   this.serverUrl = 'https://logswonhago.respectjob.com';
    //   // this.serverUrl = 'http://localhost:3001';
    //   this.imageUrl = 'https://adminwonhago.respectjob.com';
    //   // this.replaceUrl = 'https://test.charactertwin.com/block?';
    //   this.replaceUrl = 'https://pwawonhago.respectjob.com/find?';
    //   this.siteUrl = 'https://pwachartwin.respectjob.com';
    //   this.kakaoKey.callbackURL = 'http://localhost:3003/oauth';
    // } else {
    //   this.serverUrl = 'https://logs.wonhago.com';
    //   this.imageUrl = 'https://admin.wonhago.com';
    //   this.replaceUrl = 'https://test.wonhago.com/find?';
    //   this.siteUrl = 'https://test.wonhago.com';
    //   this.kakaoKey.callbackURL = this.serverUrl + '/oauth';
    // }


    // this.kakaoOauthUrl = 'https://kakao';
  }

  getVersion(param) {
    const data = param;
    data.site_code = this.siteCode;
    // return this.httpClient.post(this.serverUrl+'/version', data, {headers: this.headers}).toPromise();
    return this.httpClient.get(this.serverUrl + '/version', {headers: this.headers}).toPromise();
  }

  postLogin(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/login', data, {headers: this.headers}).toPromise();
  }

  postSignup(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/register', data, {headers: this.headers}).toPromise();
  }

  checkKakao(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/checkKakao', data, {headers: this.headers}).toPromise();
  }

  kakaoSignup(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/signupKakao', data, {headers: this.headers}).toPromise();
  }

  getMain() {
    const data = {
      site_code : this.siteCode,
    };
    return this.httpClient.post(this.serverUrl + '/main', data, {headers: this.headers}).toPromise();
  }

  getTestMain() {
    const data = {
      site_code : this.siteCode,
    };
    return this.httpClient.post(this.serverUrl + '/testMain', data, {headers: this.headers}).toPromise();
  }

  // 이벤트 기본 정보
  getEventInfo(param) {
    const data = param;
    data.site_code = this.siteCode;
    // const res = await this.httpClient.post(this.serverUrl + '/detail', param, {headers: this.headers}).toPromise();
    // console.log(['res getDetail : ', res]);
    return this.httpClient.post(this.serverUrl + '/getEventInfo', data, {headers: this.headers}).toPromise();
  }

  // async getDetail(param): Promise<any> {
  async getDetail(param) {
    const data = param;
    data.site_code = this.siteCode;
    // const res = await this.httpClient.post(this.serverUrl + '/detail', param, {headers: this.headers}).toPromise();
    // console.log(['res getDetail : ', res]);
    return await this.httpClient.post(this.serverUrl + '/detail', data, {headers: this.headers}).toPromise();

    // console.log(['res getDetail : ', res, this.data]);
    //
    // res.subscribe(rs => {
    //   console.log(['res subs ', rs]);
    // });
    //
    // return this.data;
  }

  // set Records
  setRecord(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/record', data, {headers: this.headers}).toPromise();
  }

  // gps 검증하기
  checkGps(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/checkGps', data, {headers: this.headers}).toPromise();
  }

  getMyList(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/myList', data, {headers: this.headers}).toPromise();
  }

  // 내 토큰 정보 가져오기
  getMyToken(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/myToken', data, {headers: this.headers}).toPromise();
  }

  getKakaoLogin(param) {
    const data = param;
    data.site_code = this.siteCode;
    const qs = {
      client_id: this.kakaoKey.clientID,
      redirect_uri: this.kakaoKey.callbackURL,
      response_type: 'code'
    };
    return this.httpClient.get(this.kakaoAuthUrl + '/oauth/authorize', {params: qs, headers: this.headers}).toPromise();
  }

  getNaverLogin(url) {
    return this.httpClient.get(url, { headers: {'X-Naver-Client-Id': 'Tr8ZV5odm0nygv7qmjhq', 'X-Naver-Client-Secret': 'Tpj01snn5J'}}).toPromise();
  }

  // common toast
  async showToast(msg, time) {
    const toast = await this.toast.create({
      message: msg,
      duration: time,
    });
    toast.present();
  }

  // 상품목록
  getGoodsList() {
    const data = {
      site_code : this.siteCode,
    };
    return this.httpClient.post(this.serverUrl + '/goods', data, {headers: this.headers}).toPromise();
  }

  async getGoodsDetail(param) {
    const data = param;
    // data.site_code = this.version;
    // const res = await this.httpClient.post(this.serverUrl + '/detail', param, {headers: this.headers}).toPromise();
    // console.log(['res getDetail : ', res]);
    return await this.httpClient.post(this.serverUrl + '/goodsDetail', data, {headers: this.headers}).toPromise();

    // console.log(['res getDetail : ', res, this.data]);
    //
    // res.subscribe(rs => {
    //   console.log(['res subs ', rs]);
    // });
    //
    // return this.data;
  }

  // want 기록 가져오기
  getWantHistory(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/wantHistory', data, {headers: this.headers}).toPromise();
  }

  // 희망거래 체크
  getDealCheck(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/dealCheck', data, {headers: this.headers}).toPromise();
  }

  // 당첨확인
  async getWinningCheck(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/winningCheck', data, {headers: this.headers}).toPromise();
  }

  // 구매 재고 확인
  getStockCheck(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/stockCheck', data, {headers: this.headers}).toPromise();
  }

  // 정보확인
  async getInfoCheck(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/infoCheck', data, {headers: this.headers}).toPromise();
  }

  // 정보 저장
  async setRewardInfo(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/setRewardInfo', data, {headers: this.headers}).toPromise();
  }

  getRewardList(param) {
    const data = param;
    data.site_code = this.siteCode;
    return this.httpClient.post(this.serverUrl + '/getRewardList', data, {headers: this.headers}).toPromise();
  }

  // 구매내역 저장하기
  setPurchase(param) {
    const data = param;
    // console.log(['updateCart', data]);
    data.version = this.version;

    let nodeUrl = '/purchase';
    if (this.isPayTest) {
      nodeUrl = '/purchaseTest';
    }

    return this.httpClient.post(this.serverUrl + nodeUrl, data, {headers: this.headers}).toPromise();
  }

  // 초대사용자 가져오기
  getInviteUser(param) {
    const data = param;
    data.version = this.version;

    return this.httpClient.post(this.serverUrl + '/getRewardList', data, {headers: this.headers}).toPromise();
  }

  // 이벤트 참여내역
  getEventList(param) {
    const data = param;
    data.version = this.version;

    return this.httpClient.post(this.serverUrl + '/getEventList', data, {headers: this.headers}).toPromise();
  }

  // 구매내역
  getPurchaseList(param) {
    const data = param;
    data.version = this.version;

    return this.httpClient.post(this.serverUrl + '/getPurchaseList', data, {headers: this.headers}).toPromise();
  }

  getShortLink(param) {
    console.log(['getShortLink']);
    const data = param;
    // console.log(['updateCart', data]);
    data.version = this.version;

    return this.httpClient.post(this.serverUrl + '/shortLink', data, {headers: this.headers}).toPromise();
  }

  // 디바이스 저장하기
  registerDevice(param) {
    const data = param;
    // console.log(['updateCart', data]);
    data.version = this.version;
    data.site_code = this.siteCode;

    return this.httpClient.post(this.serverUrl + '/registerDevice', data, {headers: this.headers}).toPromise();
  }

  // 사이트 정보 가져오기
  getSiteInfo(param) {
    console.log(['getSiteInfo']);
    const data = param;
    // console.log(['updateCart', data]);
    data.version = this.version;

    return this.httpClient.post(this.serverUrl + '/siteInfo', data, {headers: this.headers}).toPromise();
  }

  // 사이트 정보 가져오기
  getPopupImage(param) {
    const data = param;
    // console.log(['updateCart', data]);
    data.version = this.version;

    return this.httpClient.post(this.serverUrl + '/sitePopup', data, {headers: this.headers}).toPromise();
  }

  // 사이트 메뉴 컨텐츠 가져오기
  getSiteMenu(param) {
    const data = param;
    data.version = this.version;
    return this.httpClient.post(this.serverUrl + '/siteMenu', data, {headers: this.headers}).toPromise();
  }

  getDeviceUser(param) {
    const data = param;
    data.version = this.version;
    return this.httpClient.post(this.serverUrl + '/getDeviceUser', data, {headers: this.headers}).toPromise();
  }

  // fcm 토큰정보 저장
  setUserToken(param) {
    const data = param;
    data.version = this.version;
    return this.httpClient.post(this.serverUrl + '/setUserToken', data, {headers: this.headers}).toPromise();
  }
}
