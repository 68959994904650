import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {CommonService} from '../services/common.service';
import {RestService} from "../api/rest.service";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-intro-modal',
  templateUrl: './intro-modal.page.html',
  styleUrls: ['./intro-modal.page.scss'],
})
export class IntroModalPage implements OnInit {
  imageUrl: string;
  introImages = [];
  introImage = '';

  constructor(private apiService: ApiService,
              private common: CommonService,
              private modalCtrl: ModalController,
              private storage: Storage,
              private rest: RestService,
  ) {
    this.imageUrl = this.rest.imageUrl;
    // 이미지 가져오기
    this.rest.getPopupImage({site_code: apiService.siteCode}).then( (res) => {
      console.log(['== getPopupImage', res]);
      if (res[0].success) {
        this.introImages = res[0].images;
        this.introImage = this.introImages[Math.floor(Math.random() * this.introImages.length)].popup_image
      }
    });

  }

  ngOnInit() {
  }

  // 오늘하루 열지않음
  async todayNotOpen() {
    console.log(['todayNotOpen']);

    const toDay = new Date();
    toDay.setHours(23);
    toDay.setMinutes(59);
    toDay.setSeconds(59);
    this.storage.set(this.common.introKey, toDay.getTime()).then((res) => {
      console.log(['storage save', res]);
      this.closeModal();
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }


}
