import { Injectable } from '@angular/core';
import {User} from '../data/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User;
  constructor() { }

  getUser() {
    return this.user ? this.user : new User();
  }

  setUser(user: User) {
    this.user = user;
  }
}
