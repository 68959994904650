import { Component, OnInit } from '@angular/core';
import {PrivacyPage} from '../../privacy/privacy.page';
import {ModalController} from '@ionic/angular';
import {TermsPage} from '../../terms/terms.page';
import {TermsModalPage} from '../../terms-modal/terms-modal.page';
import {ApiService} from "../../services/api.service";
import {SiteInfoService} from "../../services/site-info.service";
declare let Kakao: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  companyinfo = {
    company_name: '(주)부치고',
    company_phone: '010-6387-7509',
    company_number: '636-88-00748',
    company_address: '서울특별시 마포구 큰우물로 76, 4층',
    company_copyright: 'BuchiGo.Inc All rights reserved.',
    ceo_name: '대표 : 최문성',
  }
  year = 2020;
  constructor(private modalCtrl: ModalController,
              private apiService: ApiService,
              private siteInfoService: SiteInfoService,
  ) {
    // this.companyinfo = this.apiService.companyinfo;
    this.year = new Date().getFullYear();

    setTimeout(() => {
      console.log('2 sec after call');
      console.log(['footer component ionViewWillEnter']);
      this.companyinfo = this.siteInfoService.getSiteInfo();
    }, 1000);
  }

  async ngOnInit() {

    // console.log(['footer init ']);
    // Kakao.PlusFriend.createChatButton({
    //   container: '#plusfriend-chat-button',
    //   plusFriendId: '_rtzZC',
    //   title: 'question',
    //   size: 'small',
    //   color: 'yellow',
    //   shape: 'mobile',
    //   supportMultipleDensities: true,
    // });

    // Kakao.PlusFriend.createChatButton({
    //   container: '#plusfriend-chat-button',
    //   plusFriendId: '_rtzZC',
    //   title: 'question',
    //   size: 'large',
    //   color: 'yellow',
    //   shape: 'mobile',
    //   supportMultipleDensities: true,
    // });
  }

  kakaoPlus() {
    console.log(['footer loadKakao ']);
    // http://pf.kakao.com/_nMBHT
    Kakao.Channel.chat({
      channelPublicId: '_nMBHT' // 카카오톡 채널 홈 URL에 명시된 id로 설정합니다.
    });
  }




  // 개인정보 처리방침
  async goPrivacy() {
    const modal = await this.modalCtrl.create({
      component: PrivacyPage,
      componentProps: {
        title: '개인정보 처리방침',
      },
    });
    return await modal.present();
  }

  // 이용약관
  async goTerms() {

    const modal = await this.modalCtrl.create({
      component: TermsModalPage
    });
    return await modal.present();
  }

  ionViewWillEnter() {

  }

}
