import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TermsPage } from './terms.page';
import {ShareComponentsModule} from '../components/share-components.module';
import {TermsComponentComponent} from '../components/terms-component/terms-component.component';

const routes: Routes = [
  {
    path: '',
    component: TermsPage
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        ShareComponentsModule
    ],
    exports: [
        TermsComponentComponent
    ],
    declarations: [TermsPage, TermsComponentComponent]
})
export class TermsPageModule {}
