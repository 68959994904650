import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {RestService} from '../../api/rest.service';
import {PaymentService} from '../../services/payment.service';
import {PaymentInfo} from '../../data/paymentInfo';
import {CommonService} from '../../services/common.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-deal',
  templateUrl: './deal.page.html',
  styleUrls: ['./deal.page.scss'],
})
export class DealPage implements OnInit {

  paymentInfo: PaymentInfo;
  imageUrl = '';
  goodsPrice = '';
  dealPrice = '';
  wantHistory: any = [];

  constructor(private alertCtrl: AlertController,
              private common: CommonService,
              private modalCtrl: ModalController,
              private route: ActivatedRoute,
              private router: Router,
              private navCtrl: NavController,
              private rest: RestService,
              private paymentService: PaymentService,
              private userService: UserService,
  ) {
    this.imageUrl = this.rest.imageUrl;
    // 결제 정보 가져오기
    console.log('paymentService', this.paymentService.getPayment(), this.userService.getUser());

    if (paymentService.getPayment()) {
      this.paymentInfo = this.paymentService.getPayment();
      // @ts-ignore
      this.goodsPrice = this.paymentInfo.deal_price;

      this.rest.getWantHistory(this.paymentInfo).then((res) => {
        console.log(['getWantHistory', res]);
        if (res && res[0].success) {
          this.wantHistory = res[0].want_history;
        }
      });

    } else {
      this.rest.showToast('정상적인 경로로 접근바랍니다.', 2000);
      this.dismiss();
    }
  }

  ngOnInit() {
  }

  dismiss() {
    console.log('dismiss 초기화');
    this.paymentInfo.deal_price = this.paymentInfo.goods_price;
    this.modalCtrl.dismiss();
  }

  async goDeal(price) {
    console.log(['deal', price]);

    // 입력한 금액이 결제 금액보다 낮으면!
    // @ts-ignore
    if (this.goodsPrice <= price) {
      const alert = await this.alertCtrl.create({
        header: '안내',
        cssClass: 'hint-alert',
        // subHeader: 'Subtitle',
        message: '바로구매 가능금액 보다 큰 금액을 입력하셨습니다.',
        buttons: [
          {
            text: '확인',
            handler: () => {
              console.log('');
            },
          },
        ],
      });
      alert.present();
      console.log(['딜 금액이 큽니다.']);
    } else {
      // @ts-ignore
      this.paymentInfo.deal_price = price;
      // @ts-ignore
      this.paymentInfo.userInfo = this.userService.getUser();
      this.paymentService.setPayment(this.paymentInfo);
      this.rest.getDealCheck(this.paymentInfo).then( async (res) => {
        console.log(['getDealCheck', res]);
        if (res && res[0].success) {
          const alert = await this.alertCtrl.create({
            header: '거래확인',
            cssClass: 'hint-alert',
            // subHeader: 'Subtitle',
            message: '희망거래에 성공하여 구매를 진행합니다.<br>취소하시면 희망거래 기회 1건은 차감됩니다.',
            buttons: [
              {
                text: '취소',
                handler: () => {
                  console.log('Confirm cancel');
                  console.log('cancel 초기화');
                  this.paymentInfo.deal_price = this.paymentInfo.goods_price;
                },
              },
              {
                text: '주문하기',
                handler: () => {
                  console.log(['Confirm Okay', this.dealPrice]);
                  // @ts-ignore
                  this.paymentInfo.want_deal = true;
                  // @ts-ignore
                  this.paymentInfo.deal_price = this.dealPrice;
                  console.log(['setPayment', this.paymentInfo]);
                  this.paymentService.setPayment(this.paymentInfo);
                  this.modalCtrl.dismiss();
                  this.navCtrl.navigateForward('/check-out');
                },
              },
            ],
          });
          alert.onDidDismiss().then(() => {
            // this.paymentInfo.deal_price = this.paymentInfo.goods_price;
          });
          alert.present();

        } else {
          const alert = await this.alertCtrl.create({
            header: '안내',
            cssClass: 'hint-alert',
            // subHeader: 'Subtitle',
            message: res[0].reason,
            buttons: [
              {
                text: '확인',
                handler: () => {
                  console.log('');
                },
              },
            ],
          });
          alert.present();
        }
      });

    }

  }

}
